import { FC, useEffect, useState } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Button,
  Radio,
  Typography,
  notification,
} from "antd";
import ServiceSelect from "components/common/ServiceSelect";
import ShopProductSelect from "components/common/ShopProductSelect";
import { useReparationServiceCreateReparationCaseItem, useReparationServiceGetReparationCaseKey } from "api/queries";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

const { Text } = Typography;

export interface CreateRCPricingItemModalProps {
  visible: boolean;
  onClose: () => void;
}

const CreateRCPricingItemModal: FC<CreateRCPricingItemModalProps> = ({
  visible,
  onClose,
}) => {
  const [form] = Form.useForm();
  const [itemType, setItemType] = useState<"PRODUCT" | "SERVICE">("PRODUCT");
  const { id: reparationCaseId } = useParams();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useReparationServiceCreateReparationCaseItem({
    onSuccess: () => {
      form.resetFields();
      notification.success({
        message: "Item added successfully",
        duration: 2,
      });
      queryClient.refetchQueries({
        queryKey: [useReparationServiceGetReparationCaseKey],
      });
      onClose();
    },
    onError: (err: any) => {
      notification.error({
        message: "Failed to add item",
        description: err?.body?.message || err?.message || err,
        duration: 2,
      });
    },
  });

  const handleSubmit = (vars: any) => {
    mutate({
      requestBody: {
        description: vars.description,
        unitPrice: vars.unitPrice,
        quantity: vars.quantity,
        totalPrice: vars.unitPrice * (vars.quantity || 1),
        itemType: itemType,
        product: vars?.productId,
        service: vars?.serviceId,
      },
      id: +reparationCaseId!,
    });
  };

  return (
    <Modal
      title="Add Item"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose} disabled={isPending}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={form.submit}
          loading={isPending}
        >
          Add Item
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        disabled={isPending}
      >
        {/* Item Type Selection */}
        <Form.Item label="Item Type">
          <Radio.Group
            value={itemType}
            onChange={(e) => setItemType(e.target.value)}
            buttonStyle="solid"
          >
            <Radio.Button value="PRODUCT">Product</Radio.Button>
            <Radio.Button value="SERVICE">Service</Radio.Button>
          </Radio.Group>
        </Form.Item>

        {/* Dynamic Selection Based on Item Type */}
        {itemType === "PRODUCT" ? (
          <Form.Item
            name="productId"
            label="Product"
            rules={[
              {
                required: itemType === "PRODUCT",
                message: "Please select a product",
              },
            ]}
          >
            <ShopProductSelect isForm={true} />
          </Form.Item>
        ) : (
          <Form.Item
            name="serviceId"
            label="Service"
            rules={[
              {
                required: itemType === "SERVICE",
                message: "Please select a service",
              },
            ]}
          >
            <ServiceSelect isForm={true} />
          </Form.Item>
        )}

        {/* Description Field */}
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: "Please enter a description" }]}
        >
          <Input.TextArea placeholder="Enter description" />
        </Form.Item>

        {/* Unit Price Field */}
        <Form.Item
          name="unitPrice"
          label="Unit Price"
          rules={[{ required: true, message: "Please enter a valid price" }]}
        >
          <InputNumber
            min={0}
            precision={2}
            style={{ width: "100%" }}
            placeholder="Enter unit price"
          />
        </Form.Item>

        {/* Quantity Field */}
        <Form.Item
          name="quantity"
          label="Quantity"
          rules={[{ required: true, message: "Please enter the quantity" }]}
        >
          <InputNumber
            min={1}
            style={{ width: "100%" }}
            placeholder="Enter quantity"
          />
        </Form.Item>

        {/* Information Message */}
        <Text type="secondary" style={{ display: "block", marginTop: "16px" }}>
          Select the type of item you want to add, choose from the list, and
          provide the necessary details.
        </Text>
      </Form>
    </Modal>
  );
};

export default CreateRCPricingItemModal;
