import { useMemo } from 'react';
import cookies from 'utils/cookies';
import ability from 'utils/abilit';

const useAuth = () => {
  // Batch read cookies
  const token = cookies.get("token");
  const isAuthenticated = !!token;
  const user = cookies.get("user");
  const role = cookies.get("role");
  const company = cookies.get("company");
  const permissions = cookies.get("permissions");

  // Memoize permissions processing
  const perms = useMemo(() => {
    if (permissions) {
      try {
        const permissionsObj = JSON.parse(permissions);
        return Object.keys(permissionsObj).map((key: string) => ({
          subject: key,
          permission: Object.keys(permissionsObj[key]).filter(
            (perm) => permissionsObj[key][perm]
          ),
        }));
      } catch (e) {
        console.error('Failed to parse permissions:', e);
        return [];
      }
    }
    return [];
  }, [permissions]);

  // Update ability with permissions
  useMemo(() => {
    ability.update(perms.map((p) => ({ subject: p.subject, action: p.permission })));
  }, [perms]);

  // Login function
  const login = (data: {
    token: string;
    user: string;
    company: any;
    role: any;
  }) => {
    cookies.set("token", data.token);
    cookies.set("user", data.user);
    cookies.set("role", data?.role ? JSON.stringify(data.role) : "");
    cookies.set("company", data?.company ? JSON.stringify(data.company) : "");
    cookies.set("permissions", JSON.stringify(data.role.rolePermissions));
    window.location.href = "/";
  };

  // Logout function
  const logout = () => {
    cookies.remove("token");
    cookies.remove("user");
    cookies.remove("role");
    cookies.remove("company");
    cookies.remove("permissions");
    window.location.href = "/login";
  };

  return {
    token,
    isAuthenticated,
    user,
    role,
    company,
    permissions,
    login,
    logout,
  };
};

export default useAuth;
