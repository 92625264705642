import { Col, Row, Typography, Button, Empty, Flex, Table } from "antd";
import { motion } from "framer-motion";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { CreateRemarkModal } from "./CreateRemarkModal";
import { useSelector } from "react-redux";

const { Title, Text } = Typography;

export const RCReviewRemarks: FC<{ data: any }> = ({ data }) => {
  const { id } = useParams<{ id: string }>();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const review = data?.reparationCaseReview;
  const company = useSelector((state: any) => state.company);

  const tableData = [
    {
      key: "description",
      label: "Description",
      value: review?.description || "No description provided",
    },
    {
      key: "pricing",
      label: "Pricing",
      value: review?.pricing ? `${company.currencySymbol} ${review.pricing}` : "No pricing provided",
    },
    {
      key: "createdBy",
      label: "Created By",
      value: review?.createdBy
        ? `${review.createdBy.userFullName} (${review.createdBy.userEmail})`
        : "No creator details available",
    },
    {
      key: "contact",
      label: "Contact",
      value: review?.createdBy?.userPhone || "No contact provided",
    },
    {
      key: "createdDate",
      label: "Created On",
      value: review?.createdDate
        ? `${new Date(review.createdDate).toLocaleDateString()} at ${new Date(
            review.createdDate
          ).toLocaleTimeString()}`
        : "No creation date provided",
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
      key="remarks"
    >
      {data?.reparationCaseReview ? (
        <Row gutter={[16, 16]} style={{ margin: "1rem 0" }}>
          <Col xs={24}>
            <div style={{ margin: "1rem 0" }}>
              <Title level={4} style={{ marginBottom: "16px", color: "#333" }}>
                Remarks
              </Title>
              <Table
                dataSource={tableData}
                columns={[
                  {
                    title: "Field",
                    dataIndex: "label",
                    key: "label",
                    render: (text) => (
                      <Text
                        strong
                        style={{ fontSize: "16px", color: "#595959" }}
                      >
                        {text}
                      </Text>
                    ),
                  },
                  {
                    title: "Details",
                    dataIndex: "value",
                    key: "value",
                    render: (text) => (
                      <Text style={{ fontSize: "16px", color: "#333" }}>
                        {text}
                      </Text>
                    ),
                  },
                ]}
                pagination={false}
                bordered
              />
            </div>
          </Col>
        </Row>
      ) : (
        <Row justify="center" align="middle" style={{ height: "50vh" }}>
          <Col xs={24}>
            <Flex
              vertical={true}
              justify="center"
              align="center"
              style={{ height: "50vh" }}
            >
              <Empty
                description="No Remarks Available"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
              <Button
                type="primary"
                style={{ marginTop: "1rem" }}
                onClick={() => setIsModalVisible(true)}
              >
                Create Remark
              </Button>
            </Flex>
          </Col>
        </Row>
      )}

      <CreateRemarkModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        caseId={id!}
      />
    </motion.div>
  );
};
