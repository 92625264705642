import { motion } from "framer-motion";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Flex,
  Input,
  message,
  Row,
  Table,
  TableColumnType,
  TablePaginationConfig,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import {
  useInventoryServiceDeletePurchaseOrder,
  useInventoryServiceGetPurchaseOrders,
} from "api/queries";
import queryString from "query-string";
import {
  IPurchaseOrder,
  IPurchaseOrderItem,
  PurchaseOrderDeliveryStatus,
  PurchaseOrderStatus,
} from "api/requests";
import { useQueryClient } from "@tanstack/react-query";
import EditCurrencyModal from "./EditPurchaseOrderModal";
import CreatePurchaseOrder from "./CreatePurchaseOrder";
import dayjs from "dayjs";
import { TableActions } from "components/common";
import { useNavigate } from "react-router-dom";
import PurchaseOrderStatusTag from "./PurchaseOrderDetails/PurchaseOrderStatusTag";
import PurchaseOrderDeliveryStatusTag from "./PurchaseOrderDetails/PurchaseOrderDeliveryStatusTag";
import PurchaseOrderPaymentProgress from "./PurchaseOrderDetails/PurchaseOrderPaymentProgress";
import { useSelector } from "react-redux";

const PurchaseOrderListing = () => {
  const [createPurchaseOrderModal, setCreatePurchaseOrderModalOpen] =
    useState(false);
  const [updatePurchaseOrderModalOpen, setUpdatePurchaseOrderModalOpen] =
    useState(false);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] =
    useState<IPurchaseOrderItem | null>(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortQuery, setSortQuery] = useState("");
  const [filtersQuery, setFiltersQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const queryClient = useQueryClient();
  const nav = useNavigate();
  const company = useSelector((state: any) => state.company);

  const onTableChange = (
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) => {
    if (pagination) {
      setPage(pagination.current || 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  useEffect(() => {
    refetch();
  }, [searchQuery]);

  const { data, isLoading, isRefetching, refetch } =
    useInventoryServiceGetPurchaseOrders({
      filters: filtersQuery,
      pagination: queryString.stringify({
        offset: (page - 1) * limit,
        limit,
      }),
      sort: sortQuery,
      searchQuery,
    });

  const { mutate: deletePurchaseOrderMutation, isPending: isDeletePending } =
    useInventoryServiceDeletePurchaseOrder({
      onSuccess: () => {
        message.success("Purchase order deleted successfully");
        refetch();
      },
      onError: (err: any) => {
        message.error(err?.body?.message || err?.message || err);
      },
    });

  const handleOpenUpdatePurchaseOrderyModal = (po: IPurchaseOrderItem) => {
    setSelectedPurchaseOrder(po);
    setUpdatePurchaseOrderModalOpen(true);
  };

  const handleCloseUpdatePurchaseOrderModal = () => {
    setSelectedPurchaseOrder(null);
    setUpdatePurchaseOrderModalOpen(false);
  };

  const handleDeletePurchaseOrder = async (id: number) => {
    await deletePurchaseOrderMutation({
      purchaseOrderId: id,
    });
  };

  const columns: TableColumnType<IPurchaseOrder>[] = [
    {
      key: "purchaseOrderNumber",
      title: "Purchase Order Number",
      dataIndex: "purchaseOrderNumber",
      sorter: true,
    },
    {
      key: "purchaseOrderDate",
      title: "Purchase Order Date",
      dataIndex: "purchaseOrderDate",
      render: (date: string) => dayjs(date).format("DD/MM/YYYY"),
      sorter: true,
    },
    {
      key: "purchaseOrderDeliveryDate",
      title: "Purchase Order Delivery Date",
      dataIndex: "purchaseOrderDeliveryDate",
      render: (date: string) => dayjs(date).format("DD/MM/YYYY"),
      sorter: true,
    },
    {
      key: "totalAmount",
      title: "Total Amount",
      dataIndex: "totalAmount",
      render: (amount: number) => `${company.currencySymbol} ${amount.toFixed(2)}`,
      sorter: true,
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (status: string) => (
        <PurchaseOrderStatusTag status={status as PurchaseOrderStatus} />
      ),
      sorter: true,
    },
    {
      key: "deliveryStatus",
      title: "Delivery Status",
      dataIndex: "deliveryStatus",
      render: (status: string) => (
        <PurchaseOrderDeliveryStatusTag
          deliveryStatus={status as PurchaseOrderDeliveryStatus}
        />
      ),
      sorter: true,
    },
    {
      key: "idPurchaseOrder",
      title: "Payments",
      dataIndex: "idPurchaseOrder",
      width: 200,
      render: (_k, record) => (
        <PurchaseOrderPaymentProgress
          total={record.totalAmount}
          current={record?.totalPaymentsAmount || 0}
        />
      ),
    },
    {
      key: "actions",
      title: "Actions",
      render: (_text, record) => (
        <TableActions
          onView={() =>
            nav(`/purchases/purchase-orders/${record.idPurchaseOrder}`)
          }
          onDeletePopConfirm={() =>
            handleDeletePurchaseOrder(record.idPurchaseOrder)
          }
          onDeletePopConfirmMessage="Are you sure?"
          onDeletePopConfirmDescription="This purchase order will be deleted permanently"
          onDeleteLoading={isDeletePending}
        />
      ),
    },
  ];

  const handleOpenCreatePurchaseOrderModal = () => {
    setCreatePurchaseOrderModalOpen(true);
  };

  const handleCloseCreateCurrencyModal = () => {
    setCreatePurchaseOrderModalOpen(false);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col xs={24} md={12}>
          <Flex justify="start" align="center">
            <Typography.Title level={2} style={{ margin: 0 }}>
              Purchase orders
            </Typography.Title>
          </Flex>
        </Col>
        <Col xs={24} md={12}>
          <Flex justify="end" align="center">
            <Button
              icon={<PlusCircleOutlined />}
              type="primary"
              onClick={handleOpenCreatePurchaseOrderModal}
            >
              Create
            </Button>
          </Flex>
        </Col>

        <Col xs={24}>
          <Divider style={{ opacity: 0.5 }} />
        </Col>

        <Col xs={24} style={{ marginBottom: 15 }}>
          <Input.Search
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search"
            style={{ flex: 1 }}
          />
        </Col>

        <Col xs={24}>
          <Table
            scroll={{ x: "max-content" }}
            key={"purchase-order-list"}
            loading={isLoading || isRefetching}
            columns={columns}
            dataSource={data?.items}
            onChange={onTableChange}
            pagination={{
              current: page,
              pageSize: limit,
              total: data?.total,
              responsive: true,
              pageSizeOptions: [10, 20, 50, 100],
              showSizeChanger: true,
            }}
          />
        </Col>
      </Row>

      <CreatePurchaseOrder
        isOpen={createPurchaseOrderModal}
        onClose={handleCloseCreateCurrencyModal}
        queryClient={queryClient}
      />

      <EditCurrencyModal
        isOpen={updatePurchaseOrderModalOpen && !!selectedPurchaseOrder}
        onClose={handleCloseUpdatePurchaseOrderModal}
        queryClient={queryClient}
        defaultValues={selectedPurchaseOrder}
      />
    </motion.div>
  );
};

export default PurchaseOrderListing;
