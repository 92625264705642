import { Button, Input, InputNumber, Select, Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import ShopProductSelect from "components/common/ShopProductSelect";
import { useMemo, useCallback } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { useInventoryServiceGetLocationProductQuantity } from "api/queries";

const { Option } = Select;

interface Item {
  key: number;
  idProduct: string;
  description: string;
  price: number;
  quantity: number;
  availableQuantity: number;
  initialQuantity: number;
  quantityInLocations: {
    idLocation: number;
    locationName: string;
    availableQuantity: number;
    movedQuantity: number;
    initialQuantity: number;
  }[];
}

export default function CreateInvoiceItemsTable({
  setItems,
  items,
  selectedLocation,
}: {
  setItems: (items: Item[]) => void;
  items: Item[];
  selectedLocation: any | null;
}) {
  const { mutate: getLocationQuantitiesForItem } =
    useInventoryServiceGetLocationProductQuantity();

  const handleItemChange = useCallback(
    async (index: number, key: keyof Item, value: string | number) => {
      const updatedItems = [...items];
      //@ts-ignore
      updatedItems[index][key] = value;

      if (key === "idProduct" && value) {
        await getLocationQuantitiesForItem(
          {
            id: +value,
          },
          {
            onSuccess: (itemQuantitiesResponse) => {
              const currentLocationId = selectedLocation;
              const quantityInLocations = [];
              let availableQuantity = 0;
              if (itemQuantitiesResponse?.length > 0) {
                for (let i = 0; i < itemQuantitiesResponse.length; i++) {
                  const locQuantity = itemQuantitiesResponse[i];
                  if (locQuantity.locationId === selectedLocation) {
                    availableQuantity = locQuantity.totalQuantity;
                  } else {
                    quantityInLocations.push({
                      idLocation: locQuantity.locationId,
                      locationName: locQuantity.locationName,
                      availableQuantity: locQuantity.totalQuantity,
                      initialQuantity: locQuantity.totalQuantity,
                      movedQuantity: 0,
                    });
                  }
                }
              }

              updatedItems[index].quantityInLocations = quantityInLocations;
              updatedItems[index].availableQuantity = availableQuantity;
              updatedItems[index].initialQuantity = availableQuantity;

              setItems(updatedItems);
            },
          }
        );
      } else {
        setItems(updatedItems);
      }
    },
    [items, setItems]
  );

  const handleRemoveItem = useCallback(
    (index: number) => {
      const updatedItems = items.filter((_, i) => i !== index);
      setItems(updatedItems);
    },
    [items, setItems]
  );

  const handleClearItem = (index: number) => {
    const updatedItems = [...items];
    const updatedItem = updatedItems[index];
    updatedItem.description = "";
    updatedItem.price = 0;
    updatedItem.quantity = 1;
    //@ts-ignore
    updatedItem.idProduct = undefined;
    setItems(updatedItems);
  };

  const renderProductSelect = useCallback(
    (index: number, record: Item) => (
      <ShopProductSelect
        isForm={false}
        selectProps={{
          style: { width: "100%" },
          value: record.idProduct,
          onClear: () => handleClearItem(index),
          onChange: (value, option) => {
            // Set the selected product ID
            handleItemChange(index, "idProduct", value);

            // Get product details directly from option's itemDetails
            //@ts-ignore
            const productDetails = option.itemDetails;

            // Update the price from the selected product's details
            handleItemChange(index, "price", productDetails?.productPrice || 0);

            // Optionally update other fields such as description
            handleItemChange(
              index,
              "description",
              productDetails?.productDescription || ""
            );
          },
        }}
      />
    ),
    [handleItemChange]
  );
  const columns: ColumnsType<Item> = useMemo(
    () => [
      {
        title: "Item",
        dataIndex: "name",
        width: 400,
        render: (text, record, index) => renderProductSelect(index, record),
      },
      {
        title: "Description",
        dataIndex: "description",
        width: 200,
        render: (text, record, index) => (
          <Input.TextArea
            value={record.description}
            rows={1}
            onChange={(e) =>
              handleItemChange(index, "description", e.target.value)
            }
          />
        ),
      },
      {
        title: "Price",
        dataIndex: "price",
        width: 100,
        render: (text, record, index) => (
          <InputNumber
            min={0}
            value={record.price}
            onChange={(value) => handleItemChange(index, "price", value ?? 0)}
          />
        ),
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        width: 100,
        render: (text, record, index) => (
          <InputNumber
            min={1}
            value={record.quantity}
            max={record.availableQuantity}
            onChange={(value) =>
              handleItemChange(index, "quantity", value ?? 1)
            }
          />
        ),
      },
      {
        title: "Action",
        dataIndex: "action",
        width: 100,
        render: (text, record, index) => (
          <>
            <Button
              onClick={() => handleRemoveItem(index)}
              icon={<DeleteOutlined />}
            ></Button>
          </>
        ),
      },
    ],
    [handleItemChange, handleRemoveItem, renderProductSelect]
  );

  const updateAvailableQuantity = async (
    item: any,
    locItem: any,
    movedValue: number
  ) => {
    const updatedItems = [...items];
    const updatedItemIndex = updatedItems.findIndex((i) => i.key === item.key);
    const updatedItem = updatedItems[updatedItemIndex];
    let movedFromOtherLocations = 0;

    if (updatedItem) {
      const selectedLocIndex = updatedItem.quantityInLocations.findIndex(
        (i) => i.idLocation === locItem.key
      );
      const selectedLoc = updatedItem.quantityInLocations[selectedLocIndex];

      if (selectedLoc) {
        selectedLoc.movedQuantity = movedValue;
        selectedLoc.availableQuantity = selectedLoc.initialQuantity;
        updatedItem.quantityInLocations[selectedLocIndex] = selectedLoc;
      }

      movedFromOtherLocations = updatedItem.quantityInLocations.reduce(
        (p, c) => {
          return p + c.movedQuantity;
        },
        0
      );

      updatedItem.availableQuantity =
        updatedItem.initialQuantity + movedFromOtherLocations;

      if (updatedItem.availableQuantity < updatedItem.quantity) {
        updatedItem.quantity = updatedItem.availableQuantity;
      }

      updatedItems[updatedItemIndex] = updatedItem;
    }

    setItems(updatedItems);
  };

  const expandedRowRender = (record?: any) => {
    const itemLocationQuantities =
      record?.quantityInLocations?.map((i: any) => {
        return {
          key: i?.idLocation,
          location: i?.locationName || "",
          quantity: i?.availableQuantity || 0,
          moveHere: i?.movedQuantity || 0, // Add this to track moved quantities
          availableQuantity: i?.availableQuantity || 0,
          initialQuantity: i?.initialQuantity || 0,
        };
      }) || [];

    return (
      <Table
        className="non-hight-auto"
        rowKey={(record) => record.key}
        columns={[
          {
            title: "Location",
            dataIndex: "location",
          },
          {
            title: "Quantity",
            dataIndex: "quantity",
          },
          {
            title: "Move here",
            dataIndex: "moveHere",
            render: (text, subRecord, index) => {
              return (
                <InputNumber
                  min={0}
                  value={subRecord.movedQuantity}
                  max={subRecord.initialQuantity}
                  onChange={(e) =>
                    updateAvailableQuantity(record, subRecord, e)
                  } // Update call here
                />
              );
            },
          },
        ]}
        dataSource={itemLocationQuantities}
      />
    );
  };
  return (
    <Table
      className="non-hight-auto"
      columns={columns}
      dataSource={items}
      pagination={false}
      expandable={{
        expandedRowRender,
      }}
      rowKey={(record) => record.key}
      scroll={{ x: "max-content" }}
      size="small"
    />
  );
}
