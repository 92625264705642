import { Select, SelectProps, Space, Tag } from "antd";
import { useInventoryServiceGetShopProducts } from "api/queries";
import { IProductItem } from "api/requests";
import queryString from "qs";
import { useEffect, useState } from "react";

interface IProductSelectProps {
  isForm?: boolean;
  onChange?: (value: any) => void;
  value?: string | number | null;
  items?: IProductItem[];
  selectProps?: Partial<SelectProps>;
}

const AllProductSelectWithForm: React.FC<IProductSelectProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const [filters, setFilters] = useState("");

  const { data, isLoading, refetch } = useInventoryServiceGetShopProducts({
    limit: 10,
    search: filters,
  });

  const handleFilter = (value: string) => {
    setFilters(value);
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      showSearch
      allowClear
      loading={isLoading || false}
      placeholder="Select a product"
      notFoundContent={false}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      options={
        //@ts-ignore
        data?.items?.map((_v) => {
          return {
            label: `${_v.productName} - ${_v.productSku}`,
            value: _v.idProduct,
            key: _v.idProduct + "product-select-product",
            itemDetails: _v,
          };
        }) ?? []
      }
      optionRender={(item) => {
        return (
          <div
            key={item.value}
            style={{ display: "flex", flexDirection: "column" }}
          >
            {item.label} - {item?.data?.itemDetails?.productSku}
            <Space wrap>
              {item?.data?.itemDetails?.productVariantValues?.length > 0 &&
                item?.data?.itemDetails?.productVariantValues?.map(
                  (variant: any) => (
                    <Tag key={variant.idVariant}>
                      {variant.productVariantValue?.name}
                    </Tag>
                  )
                )}
            </Space>
          </div>
        );
      }}
      onSearch={handleFilter}
      {...rest.selectProps}
    />
  );
};

const AllProductSelectNoForm: React.FC<IProductSelectProps> = ({
  value,
  onChange,
  ...rest
}) => {
  const [filters, setFilters] = useState("");

  const { data, isLoading, refetch } = useInventoryServiceGetShopProducts({
    limit: 10,
    search: filters,
  });

  const handleFilter = (value: string) => {
    setFilters(value);
  };

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <Select
      value={value}
      onChange={(v) => {
        //@ts-ignore
        onChange(v);
      }}
      showSearch
      allowClear
      loading={isLoading || false}
      placeholder="Select a product"
      notFoundContent={false}
      defaultActiveFirstOption={false}
      defaultValue={null}
      filterOption={false}
      options={
        //@ts-ignore
        data?.items?.map((_v: any) => {
          return {
            label: _v.productName,
            value: _v.idProduct,
            key: _v.idProduct + "product-select-product",
            itemDetails: { ..._v },
            // disabled: (+_v?.totalQuantity || 0) > 0,
          };
        }) ?? []
      }
      onSearch={handleFilter}
      {...rest.selectProps}
    />
  );
};

export default function AllProductSelect(props: IProductSelectProps) {
  if (props.isForm) {
    return <AllProductSelectWithForm {...props} />;
  }
  return <AllProductSelectNoForm {...props} />;
}
