import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, notification } from "antd";
import {
  useSettingsServiceGetCompanyDetails,
  useSettingsServiceGetCompanyDetailsKey,
  useSettingsServiceUpdateCompanyDetails,
} from "api/queries";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { setCompany } from "utils/redux/slices";

const CompanyDetails: React.FC = () => {
  const [form] = Form.useForm();
  const [companyDetails, setCompanyDetails] = useState<any>(null);

  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { data, isLoading }: any = useSettingsServiceGetCompanyDetails();

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
      setCompanyDetails(data);

      dispatch(
        setCompany({
          currency: data.baseCurrency,
          currencyCode: data.currencyCode,
          currencySymbol: data.currencySymbol,
          companyName: data.companyName,
          companyAddress: data.companyAddress,
          companyPhone: data.companyPhone,
          companyEmail: data.companyEmail,
          companyLogo: "", // Add if present in your cookies
          companyWebsite: data.companyWebsite,
          companyVat: data.companyVat || "", // Add if present in your cookies
        })
      );
    }
  }, [data]);

  const { mutate: updateCompanyDetails, isPending: isUpdating } =
    useSettingsServiceUpdateCompanyDetails({
      onError: (error: any) => {
        notification.error({
          message: "Error",
          description:
            error?.body?.message ||
            error?.response?.data?.message ||
            error?.message,
        });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [useSettingsServiceGetCompanyDetailsKey],
        });
      },
    });

  const handleSave = async (values: any) => {
    await updateCompanyDetails({
      requestBody: values,
    });
  };

  return (
    <div style={{ padding: 24 }}>
      <h1>Company Details</h1>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSave}
        initialValues={companyDetails}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Company Name"
              name="companyName"
              rules={[
                { required: true, message: "Please input the company name!" },
              ]}
            >
              <Input placeholder="Company Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Company Address"
              name="companyAddress"
              rules={[
                {
                  required: true,
                  message: "Please input the company address!",
                },
              ]}
            >
              <Input placeholder="Company Address" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Company Phone" name="companyPhone">
              <Input placeholder="Company Phone" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Company Email" name="companyEmail">
              <Input placeholder="Company Email" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Company Website" name="companyWebsite">
              <Input placeholder="Company Website" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Base Currency" name="baseCurrency">
              <Input placeholder="Base Currency" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Currency Symbol" name="currencySymbol">
              <Input placeholder="Currency Symbol" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Currency Code" name="currencyCode">
              <Input placeholder="Currency Code" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isUpdating}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CompanyDetails;
