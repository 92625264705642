import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  message,
  Row,
  Typography,
  Col,
  notification,
} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  useSettingsServiceDeleteProductVariantType,
  useSettingsServiceGetProductVariantTypes,
  useSettingsServiceGetProductVariantTypesKey,
} from "api/queries";
import qs from "qs";
import { TableActions } from "components/common";
import CreateVariantTypeModal from "./CreateVariantTypeModal";
import EditVariantTypeModal from "./EditVariantTypeModal";
import ViewVariantTypeModal from "./ViewVariantTypeModal";

const VariantComponent: React.FC = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortQuery, setSortQuery] = useState("");
  const [createVariantTypeModalOpen, setCreateVariantTypeModalOpen] =
    useState(false);
  const [editVariantTypeModalOpen, setEditVariantTypeModalOpen] =
    useState(false);
  const [viewVariantTypeModalOpen, setViewVariantTypeModalOpen] =
    useState(false);
  const [selectedVariantType, setSelectedVariantType] = useState<any>(null);

  const queryClient = useQueryClient();
  const nav = useNavigate();

  const { data, isLoading, error, isError, refetch } =
    useSettingsServiceGetProductVariantTypes(
      {
        filters: "",
        pagination: qs.stringify({
          offset: (page - 1) * limit,
          limit,
        }),
        sort: sortQuery,
      },
      [useSettingsServiceGetProductVariantTypesKey]
    );

  const { mutate: deleteVariantType, isPending: isDeleting } =
    useSettingsServiceDeleteProductVariantType();

  const handleOpenCreateVariantTypeModal = () => {
    setCreateVariantTypeModalOpen(true);
  };

  const handleCloseCreateVariantTypeModal = () => {
    setCreateVariantTypeModalOpen(false);
  };

  const handleOpenEditVariantTypeModal = (variantType: any) => {
    setEditVariantTypeModalOpen(true);
    setSelectedVariantType(variantType);
  };

  const handleCloseEditVariantTypeModal = () => {
    setEditVariantTypeModalOpen(false);
    setSelectedVariantType(null);
  };

  const handleOpenViewVariantTypeModal = (variantType: any) => {
    setViewVariantTypeModalOpen(true);
    setSelectedVariantType(variantType);
  };

  const handleCloseViewVariantTypeModal = () => {
    setViewVariantTypeModalOpen(false);
    setSelectedVariantType(null);
  };

  const handleDeleteVariantType = async (variantType: any) => {
    await deleteVariantType(
      {
        idProductVariantType: variantType.idProductVariantType,
      },
      {
        onSuccess: () => {
          message.success("Variant Type deleted successfully.");
          refetch();
        },
        onError: (error: any) => {
          notification.error({
            message: "Error deleting variant type.",
            description:
              error?.body?.message ||
              error?.response?.data?.message ||
              error?.message,
          });
        },
      }
    );
  };

  const columns: any = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Values",
      dataIndex: "productVariantValues",
      key: "productVariantValues",
      render: (values: any[]) => values?.map((v) => v.name)?.join(", ") || "",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_v: any, record: any) => (
        <TableActions
          onEdit={() => handleOpenEditVariantTypeModal(record)}
          onView={() => handleOpenViewVariantTypeModal(record)}
          onDeleteLoading={isDeleting}
          onDeletePopConfirm={() => handleDeleteVariantType(record)}
          onDeletePopConfirmDescription="Are you sure you want to delete this variant type?"
          onDeletePopConfirmMessage="This action cannot be undone."
        />
      ),
    },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (pagination && pagination.current && pagination.pageSize) {
      setPage(pagination.current || 1);
      setLimit(pagination.pageSize || 10);
    }

    if (sorter) {
      if (sorter.order) {
        const s: any = {};
        s[sorter.field] = sorter.order === "ascend" ? "asc" : "desc";
        setSortQuery(s);
      } else {
        setSortQuery("");
      }
    }
  };

  useEffect(() => {
    refetch();
  }, [page, limit, sortQuery]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.5, delay: 0 }}
    >
      <Row>
        <Col
          xs={24}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.Title level={2} style={{ margin: "0px" }}>
            Product Variant Types
          </Typography.Title>

          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={handleOpenCreateVariantTypeModal}
          >
            Add new
          </Button>
        </Col>
      </Row>
      <Row style={{ margin: "12px 0" }}></Row>
      <Row>
        <Col xs={24}>
          <Table
            style={{ height: "400px" }}
            scroll={{ x: "max-content" }}
            columns={columns}
            key={"user-list"}
            dataSource={data?.items || []}
            // rowKey={(item) => item.}
            loading={isLoading}
            pagination={{
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "40", "50"],
            }}
            onChange={handleTableChange}
          />
        </Col>

        <CreateVariantTypeModal
          isOpen={createVariantTypeModalOpen}
          onClose={handleCloseCreateVariantTypeModal}
          initialValues={selectedVariantType}
        />

        <EditVariantTypeModal
          isOpen={editVariantTypeModalOpen}
          onClose={handleCloseEditVariantTypeModal}
          initialValues={selectedVariantType}
        />

        <ViewVariantTypeModal
          isOpen={viewVariantTypeModalOpen}
          onClose={handleCloseViewVariantTypeModal}
          initialValues={selectedVariantType}
        />
      </Row>
    </motion.div>
  );
};

export default VariantComponent;

// function ViewVariantsModal({
//   isOpen,
//   onClose,
//   initialValues,
// }: {
//   isOpen: boolean;
//   onClose: () => void;
//   initialValues?: any;
// }) {
//   const [form] = Form.useForm();
//   const [variantTypeValues, setVariantTypeValues] = useState<any[]>(
//     initialValues?.variantTypeValues || []
//   );
//   const [inputValue, setInputValue] = useState("");

//   // Handle Add variant value
//   const handleAdd = () => {
//     if (!inputValue.trim()) {
//       message.error("Please enter a name before adding.");
//       return;
//     }
//     if (variantTypeValues.some((val) => val.name === inputValue.trim())) {
//       message.error("Name must be unique.");
//       return;
//     }

//     setVariantTypeValues([
//       ...variantTypeValues,
//       { idProductVariant: null, name: inputValue.trim() },
//     ]);
//     setInputValue("");
//   };

//   // Handle Remove variant value
//   const handleRemove = (index: number) => {
//     const newVariantTypeValues = [...variantTypeValues];
//     newVariantTypeValues.splice(index, 1);
//     setVariantTypeValues(newVariantTypeValues);
//   };

//   // Submit handler
//   const handleSubmit = () => {
//     form.validateFields().then((values) => {
//       const finalData = {
//         ...values,
//         variantTypeValues: variantTypeValues,
//       };
//       console.log("Final Submitted Data: ", finalData);
//       onClose(); // Close modal on submit
//     });
//   };

//   const handleClose = () => {
//     form.resetFields();
//     setVariantTypeValues(initialValues?.variantTypeValues || []);
//     onClose();
//   };

//   useEffect(() => {
//     if (initialValues) {
//       setVariantTypeValues(initialValues?.productVariantValues || []);
//     } else {
//       setVariantTypeValues([]);
//     }
//   }, [initialValues]);

//   return (
//     <Modal
//       title="Manage Variant"
//       open={isOpen}
//       onCancel={handleClose}
//       onOk={handleSubmit}
//       destroyOnClose
//     >
//       <Form form={form} initialValues={initialValues} layout="vertical">
//         <Form.Item
//           name="name"
//           label="Variant Type Name"
//           rules={[{ required: true, message: "Please enter a name" }]}
//         >
//           <Input />
//         </Form.Item>

//         <Form.Item label="Variant Values">
//           <Space direction="vertical" style={{ width: "100%" }}>
//             <Input
//               value={inputValue}
//               onChange={(e) => setInputValue(e.target.value)}
//               placeholder="Enter variant value"
//             />
//             <Button
//               onClick={handleAdd}
//               disabled={!inputValue.trim()}
//               type="primary"
//             >
//               Add Variant Value
//             </Button>
//           </Space>
//         </Form.Item>

//         <List
//           dataSource={variantTypeValues}
//           renderItem={(item, idx) => (
//             <List.Item
//               actions={[
//                 <Button danger onClick={() => handleRemove(idx)}>
//                   Remove
//                 </Button>,
//               ]}
//             >
//               {item.name}
//             </List.Item>
//           )}
//         />
//       </Form>
//     </Modal>
//   );
// }
