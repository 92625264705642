import React, { useState } from "react";
import {
  List,
  Typography,
  Button,
  Space,
  Popconfirm,
  notification,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { motion, AnimatePresence } from "framer-motion";
import CreateRCPricingItemModal from "./CreateRCPricingItemModal";
import EditRCPricingItemModal from "./EditRCPricingItemModal";
import { useSelector } from "react-redux";
import {
  useReparationServiceDeleteReparationCaseItem,
  useReparationServiceGetReparationCaseKey,
} from "api/queries";
import { useQueryClient } from "@tanstack/react-query";

const { Title, Text } = Typography;

const RCPricing = ({ data }: { data?: any }) => {
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [editItem, setEditItem] = useState<any>(null);

  const queryClient = useQueryClient();

  const { mutate, isPending } = useReparationServiceDeleteReparationCaseItem({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [useReparationServiceGetReparationCaseKey],
      });

      notification.success({
        message: "Item deleted successfully",
        duration: 2,
      });
    },
    onError: (err: any) => {
      notification.error({
        message: "Failed to delete item",
        description: err?.body?.message || err?.message || err,
        duration: 2,
      });
    },
  });

  const company = useSelector((state: any) => state.company);

  const handleEditClick = (item: any) => {
    setEditItem({
      ...item,
      product: item.product?.productName || "",
      service: item.service?.serviceName || "",
    });
    setEditModalVisible(true);
  };

  const TruncatedDescription: React.FC<{ description: string }> = ({
    description,
  }) => {
    const [expanded, setExpanded] = useState(false);

    const truncatedText =
      description.split(" ").slice(0, 5).join(" ") +
      (description.split(" ").length > 5 ? "..." : "");

    return (
      <Typography.Paragraph>
        {expanded ? description : truncatedText}{" "}
        {description.split(" ").length > 5 && (
          <Typography.Link onClick={() => setExpanded(!expanded)}>
            {expanded ? "Show less" : "Show more"}
          </Typography.Link>
        )}
      </Typography.Paragraph>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, translateY: 50 }}
      transition={{ duration: 0.5 }}
      key="pricing"
    >
      <Title level={4} style={{ marginBottom: "16px" }}>
        Pricing Items
      </Title>

      <List
        itemLayout="horizontal"
        dataSource={data.reparationItems}
        renderItem={(item: any) => (
          <AnimatePresence>
            <motion.div
              key={item.idReparationCaseItem}
              initial={{ opacity: 0, translateY: 20 }}
              animate={{ opacity: 1, translateY: 0 }}
              exit={{ opacity: 0, translateY: -20 }}
              transition={{ duration: 0.4 }}
            >
              <List.Item
                actions={[
                  <Button
                    size="small"
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => handleEditClick(item)}
                  >
                    Edit
                  </Button>,
                  <Popconfirm
                    title="Are you sure you want to delete this item?"
                    onConfirm={() => {
                      mutate({
                        id: item.reparationCase,
                        itemId: item.idReparationCaseItem,
                      });
                    }}
                  >
                    <Button
                      size="small"
                      type="link"
                      danger
                      icon={<DeleteOutlined />}
                      loading={isPending}
                    >
                      Delete
                    </Button>
                  </Popconfirm>,
                ]}
              >
                <List.Item.Meta
                  title={
                    <Text strong>
                      {item?.product?.productName || item?.service?.serviceName}
                    </Text>
                  }
                  description={
                    <Space direction="vertical" size={4}>
                      <Text type="secondary">Type: {item.itemType}</Text>
                      <Text type="secondary">
                        Unit Price: {company.currencySymbol} {item.unitPrice} |
                        Quantity: {item.quantity} | Total Price:{" "}
                        {company.currencySymbol} {item.totalPrice}
                      </Text>
                      <TruncatedDescription description={item.description} />
                    </Space>
                  }
                />
              </List.Item>
            </motion.div>
          </AnimatePresence>
        )}
      />
      <Button
        type="dashed"
        icon={<PlusOutlined />}
        style={{
          width: "100%",
          marginTop: "16px",
          height: "50px",
          fontSize: "16px",
        }}
        onClick={() => setCreateModalVisible(true)}
      >
        Add Pricing Item
      </Button>

      {/* Create Modal */}
      <CreateRCPricingItemModal
        visible={createModalVisible}
        onClose={() => setCreateModalVisible(false)}
      />

      {/* Edit Modal */}
      <EditRCPricingItemModal
        visible={editModalVisible}
        onClose={() => setEditModalVisible(false)}
        initialValues={editItem}
      />
    </motion.div>
  );
};

export default RCPricing;
